import * as React from "react";
import { Background } from "../common/components/layout/index";
import { isBrowser } from "../common/utils/gatsby/index";

import { Transition } from "./transition";

export const Layout = ({ location, children }: any) => {
  return (
    <Background className="items-center">
      {isBrowser() === false ? null : (
        <Transition location={location}>{children}</Transition>
      )}
    </Background>
  );
};

export default Layout;
