import * as React from "react";
import clsx from "clsx";

export interface IDropdown {
  label: string;
  value: string;
}

export enum DefaultValue {
  notSelected = "not-selected",
}

const defaultItem = (label: string) => ({
  label,
  value: DefaultValue.notSelected,
});

const extendDataWithDefaultItem = (data: IDropdown[], label: string) =>
  [defaultItem(label), ...data] || [defaultItem(label)];

export interface IProps {
  id: string;
  label?: string;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  data: IDropdown[];
  value: string | undefined | null;
  onChange: (id: string, value: string) => void;
}

export const Dropdown = ({
  id,
  containerClassName,
  className,
  data,
  disabled,
  placeholder,
  label,
  value,
  onChange,
}: IProps) => {
  const dropdownData = placeholder
    ? extendDataWithDefaultItem(data, placeholder)
    : data;

  const selectedData = (dropdownData || []).find((d) => d.value === value);

  return (
    <div
      className={clsx(
        "transition duration-300",
        disabled ? "pointer-events-none text-gray-400" : "text-grey-darker",
        containerClassName
      )}
    >
      {label && (
        <label
          className={clsx(
            "block mb-3 ml-4 text-xs font-bold tracking-wide uppercase whitespace-nowrap"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          "block w-full px-3 py-3 border bg-blue-10 rounded-full outline-none appearance-none border-blue-50 focus:border-blue-200",
          className
        )}
      >
        <select
          title={selectedData && selectedData.label}
          className="w-full h-full bg-transparent cursor-pointer focus:outline-none overflow-ellipsis"
          value={value || undefined}
          onChange={(e) => {
            const value = e.currentTarget.value;
            if (value === DefaultValue.notSelected) {
              return onChange(id, "");
            }

            onChange(id, e.currentTarget.value);
          }}
        >
          {dropdownData.map((d) => (
            <option title={d.label} key={`option-${d.value}`} value={d.value}>
              {d.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
