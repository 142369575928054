import { DefaultValue } from "../../components/dropdown/index";

export const isBrowser = () => typeof window !== "undefined";

export const isEmptyDropdownValue = (value: string | undefined | null) =>
  isEmptyString(value) || DefaultValue.notSelected === value;

export const isEmptyString = (value: string | undefined | null) =>
  value == null || value === "" || value.trim() === "";

export const hasEmptyValue = (data: {
  [id: string]: string | null | undefined;
}) => {
  return Object.values(data).some(
    (d) => d == null || d === "" || d.trim() === ""
  );
};

export const getUrlParams = (): URLSearchParams => {
  if (isBrowser() === false) {
    return new URLSearchParams();
  }
  return new URLSearchParams(window.location.search);
};

export const getParamValue = (key: string): string | null => {
  const params = getUrlParams();
  return params.get(key);
};

export const redirectTo = (url: string, token?: string) => {
  if (isBrowser() === false) {
    return;
  }

  if (token == null) {
    return (window.location.href = `${url}/`);
  }

  return (window.location.href = `${url}/?token=${token}`);
};
