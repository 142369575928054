import * as React from "react";

import clsx from "clsx";

import BigCircle from "../../assets/big-circle.svg";
import DotsWall from "../../assets/dots-wall.svg";
import DotsBottom from "../../assets/dots-bottom.svg";

export const Background = ({ className, children }) => {
  return (
    <div
      className={clsx(
        "absolute top-0 left-0 right-0 flex justify-center min-h-screen p-4 overflow-hidden overflow-x-hidden overscroll-none bg-gradient-to-r from-blue-25 to-blue-50",
        className ? className : "items-center"
      )}
    >
      <div
        className="absolute overflow-hidden"
        style={{ top: -240, left: -240, width: 480, height: 480 }}
      >
        <BigCircle />
      </div>
      <div
        className="absolute overflow-hidden"
        style={{ top: -2, right: -2, width: 140 }}
      >
        <DotsWall />
      </div>
      <div
        className="absolute overflow-hidden"
        style={{ bottom: 30, right: 30, width: 180 }}
      >
        <DotsBottom />
      </div>
      {children}
    </div>
  );
};
