// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-tsx": () => import("./../../../src/pages/new.tsx" /* webpackChunkName: "component---src-pages-new-tsx" */),
  "component---src-pages-recovery-tsx": () => import("./../../../src/pages/recovery.tsx" /* webpackChunkName: "component---src-pages-recovery-tsx" */),
  "component---src-pages-reset-tsx": () => import("./../../../src/pages/reset.tsx" /* webpackChunkName: "component---src-pages-reset-tsx" */),
  "component---src-pages-workspace-tsx": () => import("./../../../src/pages/workspace.tsx" /* webpackChunkName: "component---src-pages-workspace-tsx" */)
}

